import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, notification } from "antd";
import Map from "./Map/mapfunc.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Map/map.css";
import { PhoneOutlined } from "@ant-design/icons";
const MapComponent = () => {
  const { id } = useParams();
  const [picAddress, setPicAddress] = useState({ address: "" });
  const [dropAddress, setDropAddress] = useState({ address: "" });
  const [currentLocation, setCurrentLocation] = useState(null);
  const [distance, setDistance] = useState();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [api, contextHolder] = notification.useNotification();

  const BookingDate = ({ date }) => {
    const parsedDate = new Date(date); // Parse the string into a Date object
  // 

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = months[parsedDate.getMonth()];
    const day = parsedDate.getDate();
    const year = parsedDate.getFullYear();
    const hours = parsedDate.getHours() % 12 || 12;
    const minutes = parsedDate.getMinutes();
    const amPm = parsedDate.getHours() >= 12 ? "pm" : "am";

    const formattedTimestamp = `${month} ${day}, ${year} ${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;

    return formattedTimestamp;
  };

  const BookingStatus = ({ value }) => {
    const bookingStatus = [
      "Waiting-for-driver",
      "Confirmed",
      "Cancelled",
      "Enroute for pickup",
      "Loading in process",
      "Out for delivery",
      "Reached destination",
      "Un-loading",
      "Completed",
      "Queued",
    ];
    return bookingStatus[value - 1];
  };

  const handleCall = async( ) =>{
    
    let contactObj ={
      recevier:bookingDetails?.driver_id?.driver_contact_number,
      sender :bookingDetails?.customer_id?.phone_number
    }

    if(!contactObj.recevier){
      api.warning({
        message:"Notification",
        description:"Driver contact number is not available. Can't connect the call.",
        placement:"top",
        role:"alert"
      });
    }
    if(!contactObj.sender){
      api.warning({
        message:"Notification",
        description:"Customer contact number is not available. Can't connect the call.",
        placement:"top",
        role:"alert"
      });
    }
    // console.log(contactObj)

    if(contactObj.recevier && contactObj.sender){
      let res = await axios.post(
        `${process.env.REACT_APP_APP_URL}admin/booking/call-user`,
        contactObj
      );
      console.log(res)
        api.info({
          message:"Notification",
          description:res?.data?.response_msg,
          // description:"Please wait, your call is connecting...",
          placement:"top",
          role:"alert"
        });
      }
  }

  useEffect(() => {
    const fetchData = async () => {
      let response = await axios.get(
        `${process.env.REACT_APP_APP_URL}admin/booking/listBooking?id=${id}`
      );
      // console.log(data.data.data);
      if (response?.data?.data?.booking) {
        setBookingDetails(response?.data?.data?.booking);
        const {
          customer_id,
          good_type,
          fleet_partner_vehicle_id,
          goods_weight,
          pickup_address,
          pickup_coordinates,
          vehicle_type,
          contact_name,
          contact_number,
          drop_address,
          drop_coordinates,
          dropCity,
          pickupCity,
          dropstate,
          pickupstate,
          goods_amount,
          is_insurance,
          pickupdate_time,
          basepercustomer,
          baseperfleet,
          base_fare,
          add_ons_info,
          driver_id,
          ref_id,
          fleet_partner_id,
          discount,
          customer_tax_detail,
          insurance_amount,
          type,
          goods_file,
          booking_status,
          pickupDistrict,
          dropDistrict,
          supervisor_id,
        } = response.data.data.booking;

        setPicAddress({
          address: pickup_address,
          longitude: pickup_coordinates.coordinates[0],
          latitude: pickup_coordinates.coordinates[1],
          city: pickupCity,
          state: pickupstate,
          district: pickupDistrict,
        });
        setDropAddress({
          address: drop_address,
          longitude: drop_coordinates.coordinates[0],
          latitude: drop_coordinates.coordinates[1],
          city: dropCity,
          state: dropstate,
          district: dropDistrict,
        });
      }
    };
    const fetchLiveLocation = async () => {
      let response = await axios.post(
        `${process.env.REACT_APP_APP_URL}admin/booking/get-driver-trip-location-booking-details-page`,
        { booking_id: id }
      );

      if (response?.data?.data) {
        const locationArray =
          response?.data?.data?.location_coordinates?.coordinates;
        console.log(response?.data?.data);
        setCurrentLocation({
          address: response?.data?.data?.address,
          city: response?.data?.data?.city,
          longitude: locationArray[0],
          latitude: locationArray[1],
        });
      }
    };
    fetchData();
    fetchLiveLocation();
  }, []);
  // console.log(currentLocation);
  // console.log(bookingDetails);
  return (
    <>
    {contextHolder}
      <div
        style={{
          width: "99%",
          height: "65vh",
          // borderRadius: "10px",
          padding:"10px 10px",
          boxShadow:
            "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
        }}
      >
        <Map
          disabled={false}
          allowSearch={true}
          className="m-0 add-ride"
          showLocation={false}
          width={75}
          placeholder={"Search Pickup address"}
          onChange={(value) => {
            setPicAddress(value);
          }}
          location={picAddress}
          location1={dropAddress}
          currentLocation={currentLocation}
          onChangeDestination={(value) => {
            setDropAddress(value);
          }}
          destinationPlaceholder={"Search Destination address"}
          onDistanceChange={(value) => setDistance(value)}
        />
      </div>
      <Card
        style={{
          width: "85%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "20px",
        }}
      >
        {currentLocation ? null : (
          <p
            style={{
              color: "red",
              fontWeight: "bold",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            Current Location of Vehicle is not Updated !
          </p>
        )}
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={17}
            // style={{ border: "1px solid" }}
          >
            <Card title="Booking Details">
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  // style={{ border: "1px solid" }}
                >
                  <p style={{ color: "#a1a2ab", fontWeight: "500" }}>
                    Booking Id :{" "}
                    <span
                      style={{
                        fontWeight: "normal",
                        color: "black",
                        fontWeight: "500",
                      }}
                    >
                      {bookingDetails?.booking_id}
                    </span>
                  </p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  // style={{ border: "1px solid" }}
                >
                  <p style={{ color: "#a1a2ab", fontWeight: "500" }}>
                    Booking Date :{" "}
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {bookingDetails?.created_at && (
                        <BookingDate date={bookingDetails?.created_at} />
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  // style={{ border: "1px solid" }}
                >
                  <p style={{ color: "#a1a2ab", fontWeight: "500" }}>
                    Current Status :{" "}
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {bookingDetails?.booking_status && (
                        <BookingStatus value={bookingDetails?.booking_status} />
                      )}
                    </span>
                  </p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  // style={{ border: "1px solid" }}
                >
                  <p style={{ color: "#a1a2ab", fontWeight: "500" }}>
                    Pickup Location :{" "}
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {bookingDetails?.pickup_address}
                    </span>
                  </p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  // style={{ border: "1px solid" }}
                >
                  <p style={{ color: "#a1a2ab", fontWeight: "500" }}>
                    Drop Location :{" "}
                    <span style={{ color: "black", fontWeight: "500" }}>
                      {bookingDetails?.drop_address}
                    </span>
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={7} style={{}}>
            <Card title="Contact Delivery Person">
              <Row gutter={[16, 16]}>
                <Col xs={20} sm={20} md={20} lg={21} xl={20}>
                  {bookingDetails?.driver_id ? (
                    <p style={{ color: "#a1a2ab", fontWeight: "500" }}>
                      Driver Name :{" "}
                      <span style={{ color: "black", fontWeight: "500" }}>
                        {bookingDetails?.driver_id?.first_name}{" "}
                        {bookingDetails?.driver_id?.last_name}
                      </span>
                    </p>
                  ) : (
                    <h4 style={{ textAlign: "center" }}>
                      Driver Details not Available !
                    </h4>
                  )}
                </Col>
                {bookingDetails?.driver_id ? (
                  <Col
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={4}
                    style={{ marginTop: "15px" }}
                  >
                    {/* <a
                      href={`tel:+91${bookingDetails?.driver_id?.driver_contact_number}`}
                    > */}
                      <Button
                        type="primary"
                        icon={<PhoneOutlined />}
                        className="mr-2"
                        size="small"
                        onClick={handleCall}
                      ></Button>
                    {/* </a> */}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default MapComponent;
