import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch} from "react-router-dom";
import store from "./redux/store";
import Home from "./Page/home";
import MapComponent from "./Page";

function App() {
  return (
    <div>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/booking/:id" component={MapComponent} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
